<template>
    <form method="POST" :action="action">
        <slot></slot>
        <input type="hidden" name="token" :value="token">

        <div class="form-group row">
            <label for="email" class="col-md-4 col-form-label text-md-right">E-Mail Address</label>

            <div class="col-md-6">
                <input id="email" type="email" class="form-control" :class="{'is-invalid': errors.email}"
                       name="email" :value="email" required>

                <span class="invalid-feedback" role="alert" v-if="errors.email">
                    <strong>{{ errors.email[0] }}</strong>
                </span>
            </div>
        </div>

        <div class="form-group row">
            <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

            <div class="col-md-6">
                <input id="password" type="password" class="form-control" v-model="password" :class="{'is-invalid': errors.password}"
                       name="password" required autofocus>

                <div id="passwordHelpBlock" class="form-text">
                    <p  :class="[hasAtLeast8Characters ? 'text-success' : 'text-danger']"><i class="fas" :class="[hasAtLeast8Characters ? 'fa-check' : 'fa-times']"></i> At least 8 characters.</p>
                    <p  :class="[hasAtLeastOneUppercase ? 'text-success' : 'text-danger']"><i class="fas" :class="[hasAtLeastOneUppercase ? 'fa-check' : 'fa-times']"></i> At least 1 uppercase (A-Z).</p>
                    <p  :class="[hasAtLeastOneLowercase ? 'text-success' : 'text-danger']"><i class="fas" :class="[hasAtLeastOneLowercase ? 'fa-check' : 'fa-times']"></i> At least 1 lowercase (a-z).</p>
                    <p  :class="[hasAtLeastOneDigit ? 'text-success' : 'text-danger']"><i class="fas" :class="[hasAtLeastOneDigit ? 'fa-check' : 'fa-times']"></i> At least 1 digit (1-9).</p>
                    <p  :class="[hasAtLeastOneSymbol ? 'text-success' : 'text-danger']"><i class="fas" :class="[hasAtLeastOneSymbol ? 'fa-check' : 'fa-times']"></i> At least 1 special symbol (# ? ! @ $ % ^ & * - + _).</p>
                </div>

                <span class="invalid-feedback" role="alert" v-if="errors.password">
                    <strong>{{ errors.password[0] }}</strong>
                </span>
            </div>
        </div>

        <div class="form-group row">
            <label for="password-confirm" class="col-md-4 col-form-label text-md-right">Confirm Password</label>

            <div class="col-md-6">
                <input id="password-confirm" type="password" class="form-control" v-model="passwordConfirmation" name="password_confirmation" required>

                <div id="passwordConfirmationHelpBlock" class="form-text">
                    <p :class="[confirmationMatchesPassword ? 'text-success' : 'text-danger']"><i class="fas" :class="[confirmationMatchesPassword ? 'fa-check' : 'fa-times']"></i> Confirmation has to match password.</p>
                </div>
            </div>
        </div>

        <div class="form-group row mb-0">
            <div class="col-md-6 offset-md-4">
                <button type="submit" class="btn btn-primary" :disabled="!everyTestPasses">
                    Reset Password
                </button>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: "PasswordResetForm",

    props: ['action', 'token', 'email', 'formErrors'],

    data() {
        return {
            password: '',
            passwordConfirmation: '',
            errors: {}
        }
    },

    computed: {
        hasAtLeast8Characters() {
            return this.password.length >= 8;
        },
        hasAtLeastOneUppercase() {
            return /^(.*[A-Z].*)$/.test(this.password)
        },
        hasAtLeastOneLowercase() {
            return /^(.*[a-z].*)$/.test(this.password)
        },
        hasAtLeastOneDigit() {
            return /^(.*\d.*)$/.test(this.password)
        },
        hasAtLeastOneSymbol() {
            return /^(.*[\#\?\!\@\$\%\^\&\*\-\+\_].*)$/.test(this.password)
        },
        confirmationMatchesPassword() {
            if(this.password === ''){
                return false
            }
            return this.password === this.passwordConfirmation
        },
        everyTestPasses() {
            return this.hasAtLeast8Characters &&
                this.hasAtLeastOneUppercase &&
                this.hasAtLeastOneLowercase &&
                this.hasAtLeastOneDigit &&
                this.hasAtLeastOneSymbol &&
                this.confirmationMatchesPassword;
        }
    },

    mounted() {
        if(this.formErrors) {
            this.errors = this.formErrors;
            this.$forceUpdate()
        }
    },
}
</script>

<style scoped>

</style>
